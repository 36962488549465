import { useEffect } from 'react';
import { compose } from 'redux';
import {
  getGuideProfile,
  updateGuideProfile,
  guideProfileSelector,
} from 'modules/dashboard/store';
import { Empty } from 'modules/v2/common/components';
import withStore from 'store/StoreContainer';
import { isInitial } from 'store/status';

const withGuideProfile = (Component = Empty) => (props) => {
  const { guideProfile, getGuideProfile: getGuideProfileOnce } = props;

  useEffect(() => {
    if (isInitial(guideProfile.status)) {
      getGuideProfileOnce();
    }
  }, [guideProfile, getGuideProfileOnce]);

  return <Component {...props} />;
};

const mapDispatchToProps = {
  getGuideProfile,
  updateGuideProfile,
};

const mapStateToProps = (state) => ({
  guideProfile: guideProfileSelector(state),
});

export default compose(withStore(mapStateToProps, mapDispatchToProps), withGuideProfile);