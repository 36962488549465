import { useState, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { isEmpty } from 'lodash';
import { compose } from 'redux';

import { DashboardTemplate } from 'modules/v2/templates';
import { getGuideContent } from 'modules/api/strapi';
import { withProfile } from 'modules/v2/containers';
import { withAuth } from 'modules/auth/containers';
import GuideTour from '../HomePage/components/GuideTour';

import GuidePreview from './components/GuidePreview';
import mockData, { personalInformation } from './mocks/index';

const GuideCatalog = ({ profile, avatar }) => {
  const [isFirstTime, setIsFirstTime] = useState(true);
  const containerRef = useRef(null);
  const {
    data: { email, firstName, lastName, phone },
  } = profile;

  // TODO: phoneNumber, profileImage, companyName, address, companyLogo - Need to get from redux container
  const personalInfo = {
    name: `${firstName} ${lastName}`,
    email,
    ...personalInformation,
    profileImage: avatar?.data?.url || personalInformation.profileImage,
    phoneNumber: phone ? phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1.$2.$3') : personalInformation.phoneNumber,
  };

  const [loadingMessage, setLoadingMessage] = useState('Loading Guide Catalog...');
  const { data: guideResponse, isLoading:isGuideLoading } = useQuery('getGuideContent', getGuideContent);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current && 
        !containerRef.current.contains(event.target) &&
        !event.target.closest('[data-guide-tour="true"]')
      ) {
        setIsFirstTime(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  let frontCoverResponse;
  if(!isEmpty(guideResponse)){
    frontCoverResponse = guideResponse.data[0]?.attributes.frontCover;
  }

  return (
    <DashboardTemplate title="Guide Catalog" isLoading={isGuideLoading} loadingTitle={loadingMessage} contentTitle="Guide Catalog">
      <div ref={containerRef} className="bg-neutral-0 py-[15px] px-6 rounded-b-lg shadow-box">
        <div className="grid gap-5 grid-cols-[repeat(auto-fill,252px)] w-full justify-center py-[30px] px-6">
          {Array.from({ length: 1 }).map(() => (
            <GuidePreview guideDetails={mockData[0]} frontCoverResponse={frontCoverResponse} personalInformation={personalInfo} />
          ))}
        </div>
      </div>
      <GuideTour data-guide-tour="true" showModal={isFirstTime} setShowModal={setIsFirstTime} guideDetails={mockData[0]} personalInformation={personalInfo} frontCoverResponse={frontCoverResponse} />
    </DashboardTemplate>
  );
};

export default compose(withProfile, withAuth)(GuideCatalog);