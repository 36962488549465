export const CREATE_TICKET = 'dashboard/CREATE_TICKET';
export const GET_BOOKS = 'dashboard/GET_BOOKS';
export const GET_CATEGORIES = 'dashboard/GET_CATEGORIES';
export const GET_DRAFTS = 'dashboard/GET_DRAFTS';
export const GET_PROFILE = 'dashboard/GET_PROFILE';
export const GET_RELEASE_NOTES = 'dashboard/GET_RELEASE_NOTES';
export const GET_LATEST_RELEASE_NOTE = 'dashboard/GET_LATEST_RELEASE_NOTE';
export const CLEAR_ORDERS = 'dashboard/CLEAR_ORDERS';
export const GET_ORDERS = 'dashboard/GET_ORDERS';
export const SELECT_CATEGORY = 'dashboard/SELECT_CATEGORY';
export const UPLOAD_INTAKE_FORM_HEADSHOT = 'dashboard/UPLOAD_INTAKE_FORM_HEADSHOT';
export const UPLOAD_AVATAR = 'dashboard/UPLOAD_AVATAR';
export const UPDATE_PASSWORD = 'dashboard/UPDATE_PASSWORD';
export const UPDATE_PREFERENCES = 'dashboard/UPDATE_PREFERENCES';
export const UPDATE_PROFILE = 'dashboard/UPDATE_PROFILE';
export const UPDATE_PROFILE_AVATAR = 'dashboard/UPDATE_PROFILE_AVATAR';

export const GET_COUNTRIES = 'dashboard/GET_COUNTRIES';
export const GET_STATES_BY_COUNTRIES = 'dashboard/GET_STATES_BY_COUNTRIES';
export const GET_USER_ADDRESSES = 'dashboard/GET_USER_ADDRESSES';
export const ADD_USER_ADDRESS = 'dashboard/ADD_USER_ADDRESS';
export const EDIT_USER_ADDRESS = 'dashboard/EDIT_USER_ADDRESS';
export const DELETE_USER_ADDRESS = 'dashboard/DELETE_USER_ADDRESS';
export const GET_CUSTOMER_PORTAL_LINK = 'dashboard/GET_CUSTOMER_PORTAL_LINK';

export const GET_OFFERS = 'dashboard/GET_OFFERS';

export const GET_GUIDE_PROFILE = 'dashboard/GET_GUIDE_PROFILE';
export const UPDATE_GUIDE_PROFILE = 'dashboard/UPDATE_GUIDE_PROFILE';