import { getInstance } from 'modules/api/instanceStrapi';
import { queryBuilder, queryBuilderInfographics } from './builder';

const strapiInstance = getInstance();

export const getGuideContent = async () => {
  try {
    const queryString = queryBuilder();
    const url = '/api/guide-contents';
    const response = await strapiInstance.get(`${url}${queryString}`);
    if (response?.status === 200 && response?.data) {
      return response.data;
    }
    throw new Error(`Unexpected response: ${response?.status}`);
  } catch (error) {
    return `Error fetching guide content: ${error.message || error}`;
  }
};

export const getGuideInfographics = async () => {
  try {
    const queryString = queryBuilderInfographics();
    const url = '/api/guide-infographics';
    const response = await strapiInstance.get(`${url}${queryString}`);
    if (response?.status === 200 && response?.data) {
      return response.data;
    }
    throw new Error(`Unexpected response: ${response?.status}`);
  } catch (error) {
    return `Error fetching guide content: ${error.message || error}`;
  }
};