export const colorPick = {
  blue: '#4073aa',
  red: '#970E1E',
  purple: '#6f617c',
  white: '#898782',
  gold: '#c1a46c'
};

export const htmlCreation = (frontCoverResponse, personalInformation, selectedColor) => {
  const { email, name, companyName, address, phoneNumber, profileImage, companyLogo } = personalInformation;
  const phoneIcon = frontCoverResponse.phoneIcon.data.filter(item => item.attributes.name === selectedColor);
  const emailIcon = frontCoverResponse.emailIcon.data.filter(item => item.attributes.name === selectedColor);

  const finalHTMLFooter = frontCoverResponse.htmlFooter
    .replace('{{email}}', email)
    .replace('{{email}}', email)
    .replace('{{name}}', name)
    .replace('{{companyName}}', companyName)
    .replace('{{address}}', address)
    .replace('{{phoneNumber}}', phoneNumber)
    .replace('{{profileImage}}', profileImage)
    .replace('{{companyLogo}}', companyLogo)
    .replace('{{emailIcon}}', emailIcon[0]?.attributes.url || '')
    .replace('{{phoneIcon}}', phoneIcon[0]?.attributes.url || '')
    .replace('class="infoWrap1"', 'class="infoWrap1" style="width: 55%; font-size: 12.5px;padding-left: 6px;"');

  const backgroundImageResponse = frontCoverResponse.displayBgImage.data.filter(item => item.attributes.name === selectedColor);
  const backgroundImage = backgroundImageResponse[0]?.attributes.url || '';
  const colorPicker = colorPick[selectedColor];
  const finalHTML = frontCoverResponse.htmlContent
    .replace('{{Color}}', colorPicker)
    .replace('{{displayBgImage}}', backgroundImage)
    .replace('{{htmlFooter}}', finalHTMLFooter);
  return finalHTML;
};

export const frontCoverHtml = (color, pii, data) => {
  // Footer change
  const { email, name, companyName, address, phoneNumber, profileImage, companyLogo } = pii;
  const phoneIcon = data.phoneIcon.data.filter(item => item.attributes.name === color);
  const emailIcon = data.emailIcon.data.filter(item => item.attributes.name === color);

  const finalHTMLFooter = data.htmlFooter
    .replace('{{email}}', email)
    .replace('{{email}}', email)
    .replace('{{name}}', name)
    .replace('{{companyName}}', companyName)
    .replace('{{address}}', address)
    .replace('{{phoneNumber}}', phoneNumber)
    .replace('{{profileImage}}', profileImage)
    .replace('{{companyLogo}}', companyLogo)
    .replace('{{emailIcon}}', emailIcon[0]?.attributes.url || '')
    .replace('{{phoneIcon}}', phoneIcon[0]?.attributes.url || '');

  // content change
  const backgroundImageResponse = data.displayBgImage.data.filter(item => item.attributes.name === color);
  const backgroundImage = backgroundImageResponse[0]?.attributes.url || '';
  const colorPicker = colorPick[color];
  const finalHTML = data.htmlContent
    .replace('{{Color}}', colorPicker)
    .replace('{{displayBgImage}}', backgroundImage)
    .replace('{{htmlFooter}}', finalHTMLFooter);
  return finalHTML;
};

export const insideContent = (color, data ) => {
  const { innerFooter } = data;
  const backgroundImageResponse = data.displayBgImage.data.filter(item => item.attributes.name === color);
  const colorPicker = colorPick[color];
  const contentImageResponse = data.contentImages.data;
  let finalHTML = data.html
    .replace('{{Color}}', colorPicker)
    .replace('{{displayBgImage}}', backgroundImageResponse[0]?.attributes.url || '')
    .replace('{{innerFooter}}', innerFooter);
  if(contentImageResponse !== null){
    const contentImage = contentImageResponse.filter(item => item.attributes.name === color);
    finalHTML = finalHTML.replace('{{contentImages}}', contentImage[0]?.attributes.url || '');
  }
  return finalHTML;
};

export const backFooterHtml = (color, pii, data) => {
  const { email, name, companyName, address, phoneNumber, profileImage, companyLogo } = pii;

  const phoneIcon = data.phoneIcon.data.filter(item => item.attributes.name === color);
  const locationIcon = data.locationIcon.data.filter(item => item.attributes.name === color);
  const webIcon = data.webIcon.data.filter(item => item.attributes.name === color);

  const finalHTML = data.htmlFooter
    .replace('{{email}}', email)
    .replace('{{email}}', email)
    .replace('{{name}}', name)
    .replace('{{companyName}}', companyName)
    .replace('{{address}}', address)
    .replace('{{phoneNumber}}', phoneNumber)
    .replace('{{profileImage}}', profileImage)
    .replace('{{companyLogo}}', companyLogo)
    .replace('{{phoneIcon}}', phoneIcon[0]?.attributes.url || '')
    .replace('{{emailIcon}}', webIcon[0]?.attributes.url || '')
    .replace('{{locationIcon}}', locationIcon[0]?.attributes.url || '');

  return finalHTML;
};

export const backCoverHtml = (color, data, backCoverStrip) => {
  const backgroundImageResponse = data.displayBgImage.data.filter(item => item.attributes.name === color);
  const backgroundImage = backgroundImageResponse[0]?.attributes.url || '';
  const colorPicker = colorPick[color];
  const finalHTML = data.html
    .replace('{{Color}}', colorPicker)
    .replace('{{displayBgImage}}', backgroundImage)
    .replace('{{htmlFooter}}', backCoverStrip);
  return finalHTML;
};

export const htmlCreationInfographics = (data, pii, color) => {
  console.log(data);
  
  const { email, name, companyName, phoneNumber, profileImage } = pii;
  const phoneIcon = data.phoneIcon.data[0]?.attributes.url;
  const emailIcon = data.emailIcon.data[0]?.attributes.url;
  const backgroundImageResponse = data.displayBgImage.data.filter(item => item.attributes.name === `${color}.jpg`);
  const backgroundImage = backgroundImageResponse[0]?.attributes.url || '';
  const colorPicker = colorPick[color];
  const finalHTML = data.html
    .replace('{{email}}', email)
    .replace('{{email}}', email)
    .replace('{{name}}', name)
    .replace('{{companyName}}', companyName)
    .replace('{{phoneNumber}}', phoneNumber)
    .replace('{{color}}', colorPicker)
    .replace('{{displayBgImage}}', backgroundImage)
    .replace('{{profileImage}}', profileImage)
    .replace('{{phoneIcon}}', phoneIcon || '')
    .replace('{{emailIcon}}', emailIcon || '');
  return finalHTML;
}

export const strapiHtmlCreation = (data, personalInformation, selectedColor) => {
  const htmlPages = [];

  // Add front cover
  const frontCoverDesign = frontCoverHtml(selectedColor, personalInformation, data?.frontCover);
  htmlPages.push(frontCoverDesign);

  // Add content pages
  const frontInsideCover = data?.content;
  frontInsideCover.forEach(item => {
    const frontCoverInside = insideContent(selectedColor, item);
    htmlPages.push(frontCoverInside);
  });

  // Add back cover
  const backCoverStrip = backFooterHtml(selectedColor, personalInformation, data?.backCover);
  const backCoverDesign = backCoverHtml(selectedColor, data?.backCover, backCoverStrip);
  htmlPages.push(backCoverDesign);

  return htmlPages;
};

export const strapiHtmlInfographicCreation = (data, personalInformation, selectedColor) => { 

  const frontCoverDesign = htmlCreationInfographics(data?.cover[0], personalInformation, selectedColor);

  return frontCoverDesign;
};
