import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { compose } from 'redux';
import { CheckIconGuides } from 'modules/v2/common/components/SvgIcon';
import { Button } from 'modules/v2/common/AtomicDesign/atoms';
import { getMyCheckout, getRouteGuidePreview } from 'modules/v2/routes/navigation';
import { checkButton, GuideCheckoutStatus } from 'modules/v2/utils';
import { cn } from 'modules/v2/common/utils';
import { htmlCreation } from 'modules/v2/pages/GuideCatalog/utils/htmlCreation';
import { withGuideProfile } from 'modules/dashboard/containers';

const GuidePreview = ({ guideDetails, frontCoverResponse, personalInformation, guideProfile, updateGuideProfile }) => {
  const { guidePreferences } = guideProfile;
  const { name, guideColor, guideData, title, description } = guideDetails;
  const [selectedColor, setSelectedColor] = useState('blue');
  const [selectedPreference, setSelectedPreference] = useState(null);
  const [selectedCoverImage, setSelectedCoverImage] = useState(guideData[0]);
  const history = useHistory();

  const handleCheckout = (status, orderId) => {
    history.push(getMyCheckout(status, orderId));
  };

  const handlePreviewClick = (guideName: string) => {
    history.push(getRouteGuidePreview(guideName));
  };

  useEffect(() => {

    if (guidePreferences.length === 0){
      return;
    }
    const selected = guidePreferences.find((ele) => ele.name === name);
    setSelectedPreference(selected);
    setSelectedColor(selected.color);
  }, []);

  const handleColorChange = (colorItem) => {

    setSelectedColor(colorItem);
    const selectedImg = guideData.filter(item => item.color === colorItem);
    setSelectedCoverImage(selectedImg[0]);

    if (selectedPreference) {
      setSelectedPreference({ ...selectedPreference, color: colorItem });
      const newGuidePreferences = guidePreferences.map((ele) => {
        if (ele.name === name) {
          return { ...ele, color: colorItem };
        }
        return ele;
      } );
      updateGuideProfile({ guidePreferences: newGuidePreferences });
    } else {
      setSelectedPreference({ name, title, color: colorItem });
      const newGuidePreferences = [...guidePreferences, { name, title, color: colorItem }];
      updateGuideProfile({ guidePreferences: newGuidePreferences });
    }
  };

  const checkFinalizeStatus = selectedCoverImage.orderStatus === GuideCheckoutStatus.FINALIZE;

  const finalHTML = htmlCreation(frontCoverResponse, personalInformation, selectedColor);

  return (
    <div>
      <div className="relative group w-[252px] h-[332px] rounded-lg">
        <div className='bg-grey-100 relative w-[252px] h-[332px] rounded-lg border neutral-300 overflow-hidden cursor-pointer transition-transform duration-300 ease-in-out' >
          <div
            className="absolute top-0 left-0 w-full h-full origin-top-left scale-30"
            dangerouslySetInnerHTML={{ __html: finalHTML }}
          />
        </div>

        <div className="absolute inset-0 bg-black bg-opacity-40 rounded-lg flex flex-col items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
          <Button type='outlined' className='mb-2 px-14' onClick={() => handlePreviewClick(name)}>Preview</Button>
          <Button type='primary' className={cn('px-12', {
            'px-6': checkFinalizeStatus,
          })} onClick={() => handleCheckout(selectedCoverImage.orderStatus, selectedCoverImage.orderId)}> {checkButton(selectedCoverImage.orderStatus)} </Button>
        </div>
      </div>


      <div className="py-2 flex gap-2 flex-row">
        {guideColor.map((item) => (
          <div
            key={item}
            className={cn(`h-[26px] w-[26px] flex items-center justify-center rounded-full cursor-pointer bg-guides-${item}`)}
            onClick={() => handleColorChange(item)}
          >
            {selectedColor === item && <CheckIconGuides />}
          </div>
        ))}
      </div>
      <div className='text-sm font-medium'>
        <p className='truncate'>{title}</p>
        <span className='text-neutral-500'>{name}</span>
      </div>
    </div>
  );
};

export default compose(withGuideProfile)(GuidePreview);