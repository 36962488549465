import classNames from 'classnames';
import React from 'react';

interface BoxProps {
  children: React.ReactNode;
  className?: string;
  full?: boolean;
}

const defaultProps: Partial<BoxProps> = {
  className: '',
  full: true,
};

const Box = ({ children, className, full }: BoxProps) => (
  <div className={classNames('bg-white h-fit rounded-lg shadow-box mb-2', full ? 'w-full' : '', className)}>
    {children}
  </div>
);

Box.defaultProps = defaultProps;

export default Box;
