
import { ChevronLeft, ChevronRight } from 'modules/v2/common/components/SvgIcon';
import { cn } from 'modules/v2/common/utils';


const NavigationArrow = ({ direction, onClick, disabled }: { direction: 'left' | 'right'; onClick: () => void; disabled: boolean }) => (
  <button
    type="button"
    onClick={onClick}
    disabled={disabled}
    className={cn(
      'absolute top-1/2 -translate-y-1/2 bg-neutral-100 transition-colors flex w-[38px] h-[38px] items-center justify-center gap-2 p-2.5 rounded-md overflow-hidden',
      {
        'hover:bg-neutral-200': !disabled,
        'opacity-50 cursor-not-allowed': disabled
      }
    )}
    style={{ [direction]: '1rem' }}
  >
    {direction === 'left' ? (
      <ChevronLeft />
    ) : (
      <ChevronRight />
    )}
  </button>
);

export default NavigationArrow;