import { useCallback, useEffect, useState } from 'react';
import { compose } from 'redux';
import { Form, Formik } from 'formik';
import env from 'environments';
import { APIProvider, Map, Pin, AdvancedMarker } from '@vis.gl/react-google-maps';
import { postJourneyTodos } from 'modules/api/common';
import { getUser } from 'modules/api/afyLogger/afyLogger';
import { TaskCard, SelectField } from 'modules/v2/common/components';
import TextInput from 'modules/v2/common/AtomicDesign/atoms/TextInput';
import { LabelInput, Button } from 'modules/v2/common/AtomicDesign/atoms';
import AddressGoogleLookup from 'modules/v2/common/components/AddressGoogleLookup';
import { PinLocationIcon } from 'modules/v2/common/components/SvgIcon';
import { getItem, setItem } from 'modules/dashboard/utils/legacy';
import { getData } from 'modules/auth';
import { getRouteHomePage } from 'modules/v2/routes/navigation';
import { updatePasswordFlag } from 'modules/api/auth';

import { withAddress, withCountries } from 'modules/v2/containers';
import { get, isEmpty, map } from 'lodash-es';
import { cn } from 'modules/v2/common/utils/cn';
import validationSchema from 'modules/editor/components/AddressForm/validationSchema';
import MapHandler from './MapHandler';
import S from './styles';

const AddressForm = (
  //     {
  //   // DATA
  //   // addresses,
  //   countries,
  //   states,

  //   // Callbacks
  //   addUserAddresses,

  //   // Props
  //   history,
  // }
) => {
  const countries = {};
  const states = {};
  const addUserAddresses = () => { };
  const history = {};
  // eslint-disable-next-line no-undef
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [geocodeLocation, setGeocoderLocation] = useState(null);
  const [userData, setUserData] = useState(null);
  const [isSavingAddress, setSavingAddress] = useState(false);
  const [isManualEntry, setIsManualEntry] = useState(false);
  const [isLastStep, setIsLastStep] = useState(false);

  const API_KEY = env.GOOGLE_MAP_API_KEY;

  const fields = {
    firstName: userData?.firstname,
    lastName: userData?.lastname,
    phoneNumber: '',
    addressLine1: '',
    city: '',
    country: '',
    pincode: '',
    state: '',
    isDefault: true,
  };

  const card = {
    icon: <PinLocationIcon />,
    title: 'Your shipping address',
    as: 'normal',
  };

  //   useEffect(() => {
  //     initializeTodos(fetchedTodos);
  //   }, [fetchedTodos]);

  //   useEffect(() => {
  //     if (!isEmpty(tasks)) {
  //       const getCompletedTasks = todos.filter(
  //         (item) =>
  //           SHIPPING_SETUP_TASKS_CHECK.includes(item.name) && item.status === TASK_STATUS.COMPLETED,
  //       );
  //       if (getCompletedTasks.length === 2) {
  //         setIsLastStep(true);
  //       }
  //     }
  //   }, [tasks, todos]);

  //   const handleSubmitAddress = useCallback(
  //     (stepName) => {
  //       const path = generateClickUrl(stepName);
  //       return history.push(`${path}&completed=${tasks?.setupShippingAddress?.name}`);
  //     },
  //     [history, tasks],
  //   );

  //   const handleNavigateHomeClick = useCallback(() => {
  //     const { userData: userMemberData } = getData();
  //     const updatedUser = {
  //       ...userMemberData,
  //       welcomeJourneyEligible: false,
  //     };
  //     setItem('userData', updatedUser);
  //     const path = getRouteHomePage();
  //     history.push(`${path}?welcomeJourneyCompleted`);
  //   }, [history]);

  //   const handleCancelClick = useCallback(() => {
  //     const path = generateClickUrl();
  //     return history.push(`${path}`);
  //   }, [history, tasks]);

  const handleShippingSubmit = useCallback(
    (field) => {
      setSavingAddress(true);
      field.firstName = userData?.firstname;
      field.lastName = userData?.lastname;
      const callback = addUserAddresses;
      const cbResult = callback(field);
      if (cbResult) {
        cbResult.then(async () => {
          const response = await postJourneyTodos({
            taskId: tasks?.setupShippingAddress?.taskId,
            stepId: tasks?.setupShippingAddress?.stepId?.setupShippingAddress,
          });
          //   if (response && response.status === 201) {
          //     completed({
          //       [tasks?.setupShippingAddress?.name]: 'completed',
          //     });
          //     if (isLastStep) {
          //       const { userData: userMemberDetails } = getData();
          //       await updatePasswordFlag(userMemberDetails.email, false, false);
          //       handleNavigateHomeClick();
          //     // } else {
          //     //   handleSubmitAddress('');
          //     }
          //   }
        });
        setSavingAddress(false);
      }
    },
    [addUserAddresses, userData],
  );

  useEffect(() => {
    const cacheData = JSON.parse(getItem('userData'));
    const user = getUser();
    if (cacheData) {
      cacheData.id = user?.id;
      setUserData(cacheData);
    }
  }, []);

  return (
    <Formik
      initialValues={fields}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur
      onSubmit={handleShippingSubmit}
      enableReinitialize
    >
      {({ values, errors, touched, handleSubmit, setFieldValue }) => {
        const optionBuilder = (item) => ({
          title: item,
          value: item,
        });

        const selectedCountry = values.country;
        const selectedStateList = get(states.data, selectedCountry);

        const countryList = map(countries.data, optionBuilder);
        const stateList = map(selectedStateList, optionBuilder);

        const handleCountryChange = () => setFieldValue('state', undefined);

        const onChangeField = (e) => {
          const { name, value } = e.target;
          setFieldValue(name, value);
        };

        return (
          <Form onSubmit={handleSubmit} className="address-form p-0 w-full">
            <APIProvider apiKey={API_KEY} libraries={['marker']}>
              <div className="flex flex-col items-center justify-center gap-8 focus:border-primary-500">
                <div className="w-full h-auto">
                  <div>
                    <div
                      className={cn('scale-0 opacity-0 transition-all ease-in-out duration-300', {
                        'scale-100 opacity-100 p-0 border-2 border-neutral-200 rounded-lg overflow-hidden mb-[20px]': selectedPlace,
                      })}
                    >
                      <Map
                        mapId="bf51a910020fa25a"
                        style={{
                          width: '100%',
                          height: selectedPlace && '250px',
                          borderRadius: '8px',
                        }}
                        defaultZoom={15}
                        defaultCenter={{
                          lat: selectedPlace?.geometry?.location.lat(),
                          lng: selectedPlace?.geometry?.location.lat(),
                        }}
                        gestureHandling="greedy"
                        disableDefaultUI
                      />
                    </div>
                    {selectedPlace && (
                      <AdvancedMarker
                        position={{
                          lat: selectedPlace?.geometry?.location.lat() || 0,
                          lng: selectedPlace?.geometry?.location.lng() || 0,
                        }}
                        title={selectedPlace?.formatted_address}
                      >
                        <Pin background="#4CC9E1" borderColor="#22ccff" glyphColor="#fff" />
                      </AdvancedMarker>
                    )}

                    <MapHandler place={selectedPlace} />

                    <AddressGoogleLookup
                      setSelectedPlace={setSelectedPlace}
                      setGeocoderLocation={setGeocoderLocation}
                      setFieldValue={setFieldValue}
                      setIsManualEntry={setIsManualEntry}
                    />

                    <div
                      className={cn('mt-4 gap-4 flex flex-col', {
                        'hidden p-0': isEmpty(geocodeLocation?.results) && !isManualEntry,
                      })}
                    >
                      <div>
                        <LabelInput>Apartment, suite, unit, etc. (if applicable)</LabelInput>
                        <TextInput
                          type="text"
                          sizing="sm"
                          name="addressLine2"
                          placeholder="Optional"
                          onChange={onChangeField}
                        />
                      </div>
                      <div className="w-full flex gap-3 max-[650px]:flex-col">
                        <div className="w-full">
                          <LabelInput required>City</LabelInput>
                          <TextInput
                            type="text"
                            name="city"
                            sizing="sm"
                            value={values.city}
                            onChange={onChangeField}
                            errorMessage="City is required"
                            isValid={!errors.city && touched.city}
                          />
                        </div>

                        <div className="w-full flex flex-col">
                          <LabelInput required className="mb-[0px]">
                            Country
                          </LabelInput>
                          <S.FormikField
                            component={SelectField}
                            name="country"
                            placeholder="Country"
                            hasError
                            type="text"
                            value={values.country}
                            options={countryList}
                            onChange={handleCountryChange}
                          />

                          {errors.country && (
                            <span className="text-error-500 text-sm">{errors.country}</span>
                          )}
                        </div>
                      </div>
                      <div className="w-full flex gap-3 max-[650px]:flex-col">
                        <div className="w-full">
                          <LabelInput required>Zip/Postal code</LabelInput>
                          <TextInput
                            type="text"
                            name="pincode"
                            sizing="sm"
                            value={values.pincode}
                            onChange={onChangeField}
                            errorMessage="Zip code is required"
                            isValid={!errors.pincode && touched.pincode}
                          />
                        </div>

                        <div className="w-full flex flex-col">
                          <LabelInput required className="mb-[0px]">
                            State/Province
                          </LabelInput>
                          <S.FormikField
                            component={SelectField}
                            name="state"
                            placeholder="State"
                            hasError
                            type="text"
                            value={values.state}
                            options={stateList}
                          />
                          {errors.state && (
                            <span className="text-error-500 text-sm">{errors.state}</span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="w-full flex gap-1 mt-6">
                      <Button color="light" onClick={() => handleCancelClick()}>
                        Cancel
                      </Button>
                      <Button type="primary" buttonType="submit" disabled={isSavingAddress}>
                        Add shipping address
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </APIProvider>
          </Form>
        );
      }}
    </Formik>
  );
};

const Composed = compose(withAddress, withCountries)(AddressForm);

export default Composed;
