import { CustomFlowbiteTheme, Modal } from 'flowbite-react';

import { Box, Button } from 'modules/v2/common/AtomicDesign/atoms';
import { ChecklistIcon, CheckIconGuides } from 'modules/v2/common/components/SvgIcon';

const customTheme: CustomFlowbiteTheme['modal'] = {
  root: {
    show: {
      on: 'flex bg-neutral-900 bg-opacity-80',
      off: 'hidden',
    },
  },
  sizes: {
    "full": "max-w-full",
  },
  content: {
    base: 'relative h-full max-sm:w-full md:h-auto font-[figtree]',
    "inner": "relative flex flex-col rounded-lg bg-transparent shadow dark:bg-gray-700"
  },
  "positions": {
    "center-left": "items-center justify-start",
  },
  body: {
    base: 'px-6',
  },
  footer: {
    base:
      'flex space-x-2 rounded-b border-neutral-200 min-h-[91px] px-6 pb-[13px] pt-[34px] border-neutral-600',
  },
};

const FisrtTimeAccess = ({ showModal, setShowModal, isDigitalOnly }) => {

  return (
    <Modal
      theme={customTheme}
      show={showModal}
      onClose={() => setShowModal(false)}
      size="full"
      popup
      position={'center-left'}
    >
      <div className='flex flex-wrap gap-3.5 ml-[300px] max-lg:ml-0'>
        <Box className='p-8 w-[300px]' full={false}>
          <div className='w-full max-w-[300px]' >
            <div className='text-lg font-semibold flex items-center gap-2.5 mb-3.5'>
              <ChecklistIcon />
              <span>Setup checklist</span>
            </div>

            <div className='text-sm font-semibold flex items-center gap-3 py-2.5 px-3 rounded-md bg-neutral-100'>
              <div className='w-4 h-4 rounded-full flex items-center justify-center border border-neutral-200 bg-success-500'>
                <CheckIconGuides width='10' height='10' />
              </div>
              <span>Your Personal Information</span>
            </div>
          </div>

          <div className='w-full mb-6'>
            <div className='text-sm font-semibold flex items-center gap-3 py-2.5 px-3 rounded-md mt-2'>
              <div className='w-4 h-4 rounded-full flex items-center justify-center border border-neutral-200 bg-white'>
                <CheckIconGuides width='10' height='10' />
              </div>
              <span>{isDigitalOnly ? 'Your Address' : 'Your Shipping Address'}</span>
            </div>
          </div>
        </Box>
        <Box className='p-5 w-[286px]' full={false}>
          <p className='font-semibold leading-5'>Complete this checklist to ensure your guides are setup correctly.</p>
          <Button onClick={() => setShowModal(false)} size='sm' className='w-[57px] mt-3.5'>
            Okay
          </Button>
        </Box>
      </div>
    </Modal>
  );
};

export default FisrtTimeAccess;
