import { GET_GUIDE_PROFILE, UPDATE_GUIDE_PROFILE } from 'modules/dashboard/store/constants';
import { guideProfile as initialState } from '../initialState';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_GUIDE_PROFILE}`: {
      return { ...state };
    }

    case `${UPDATE_GUIDE_PROFILE}`: {
      const data = action.payload ?? null;
      return {
        ...state,
        ...data,
      };
    }

    default:
      return state;
  }
};

export default reducer;