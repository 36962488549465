import { useEffect, useState } from 'react';
import env from 'environments';
import { getImageDimensions } from 'modules/dashboard/utils/image';
import { notification } from 'modules/v2/common/utils';

import Modal from 'modules/v2/common/components/Modal';
import Loader from 'modules/v2/common/components/Loader';
import { PrimaryButton, CancelButton } from 'modules/v2/common/components';
import PageProfileModal from 'modules/dashboard/pages/PageProfile/components/PageProfileModal';
import { validateImageURL } from '../FilePicker/components/ImageUploader/validation';

const BrokerLogoModalPreferences = ({
  type = 'photo',
  isOpen,
  onSuccess,
  onCancel,
  title = 'Upload Photo',
  path = '',
  imageUrlForEdit,
  toggleCancelEdit,
}) => {
  const [landingPageImage, setLandingPageImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState(null);

  const onImageConfirm = async () => {
    if (!image && !landingPageImage) return;
    onSuccess?.(image?.imageUrl);
  };

  const selectedProfileLogo = (e) => {
    setLandingPageImage(e);
  };

  const onImageClick = async (img) => {
    const dimensions = await getImageDimensions(img.imageUrl);
    validateImageURL(dimensions.width, dimensions.height, type)
      .then(() => setImage(img))
      .catch((error) => {
        notification.error({
          description: error.message,
        });
        throw new Error(error.message);
      });
  };

  useEffect(() => {
    onImageConfirm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  let footerButtons = null;

  if (type === 'profile' || type === 'preview-book-image' || type === 'email-template-header') {
    footerButtons = [
      <PrimaryButton key="primary-button" onClick={onImageConfirm}>
        Ok
      </PrimaryButton>,
      <CancelButton key="cancel-button" onClick={onCancel}>
        Cancel
      </CancelButton>,
    ];
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onCancel={onCancel}
        maskClosable={false}
        title={title}
        width="auto"
        footer={footerButtons}
        hasCloseButton
      >
        {isLoading ? (
          <Loader height="75vh" hasIcon={false} description="Please wait, uploading image" />
        ) : (
          <PageProfileModal
            type={type}
            selectedProfileLogo={selectedProfileLogo}
            onImageClick={onImageClick}
            imageUrlForEdit={imageUrlForEdit}
            toggleCancelEdit={toggleCancelEdit}
            onSuccess={onSuccess}
          />
        )}
      </Modal>
    </>
  );
};

export default BrokerLogoModalPreferences;
