import join from 'url-join';
import { push } from 'connected-react-router';
import { generatePath } from 'react-router-dom';

import env from 'environments';

import {
  ROUTE_DASHBOARD,
  ROUTE_HOMEPAGE,
  ROUTE_BILLING_INFO,
  ROUTE_BOOK_CATALOG,
  ROUTE_CHANGE_PASSWORD,
  ROUTE_EDIT_SHIPPING_ADDRESS,
  ROUTE_HELP_CENTER,
  ROUTE_LOGIN,
  ROUTE_MY_ORDERS,
  ROUTE_MY_CHECKOUT,
  ROUTE_DIGITAL_BOOKS,
  ROUTE_PROFILE,
  ROUTE_AUTO_LOGIN,
  ROUTE_RELEASE_NOTES,
  ROUTE_LEADS,
  ROUTE_IMPORT_LEADS,
  ROUTE_EMAIL_SENDER_EMAIL_CONFIGURATION,
  ROUTE_EMAIL_TEMPLATES,
  ROUTE_EMAIL_CAMPAIGNS_ANALYTICS,
  ROUTE_CREATE_EMAIL_CAMPAIGN,
  ROUTE_EMAIL_LAUNCH_CAMPAIGN,
  ROUTE_CAMPAIGNS_HISTORY,
  ROUTE_ALL_CAMPAIGN_METRICS,
  ROUTE_EMAIL_CAMPAIGN,
  ROUTE_ON_DEMAND_EMAIL,
  ROUTE_CREATE_ON_DEMAND_EMAIL,
  ROUTE_BOOK_PERFORMANCE,
  ROUTE_PLANS,
  ROUTE_BILLING,
  ROUTE_UPGRADE_PLAN,
  ROUTE_PAGE_PROFILE,
  ROUTE_EMAIL_HISTORY,
  ROUTE_REFERRAL_MARKETING_MAGAZINE_EDITOR,
  ROUTE_REFERRAL_MARKETING_GENERATED_MAGAZINE,
  ROUTE_MANAGE_BOOK_VERSION,
  ROUTE_CREATE_CUSTOMER_TEMPLATE,
  ROUTE_EDIT_CUSTOMER_TEMPLATE,
  ROUTE_REFERRAL_MARKETING_PREVIOUS_MAGAZINES,
  ROUTE_REFERRAL_MARKETING_MAGAZINE_RECIPIENTS,
  ROUTE_PAYMENTS,
  ROUTE_PROFILE_PLANS,
  ROUTE_PLANS_AND_BILLING,
  ROUTE_PLAN_UPGRADE,
  ROUTE_CHECKOUT,
  ROUTE_BUY_CREDITS,
  ROUTE_WELCOME_JOURNEY,
  ROUTE_GUIDE_CATALOG,
  ROUTE_GUIDE_PREVIEW,
  ROUTE_MY_ORDER,
} from './constants';

export const getRouteHomePage = (orderId) => {
  if (orderId) {
    const routeWithOrderId = `${ROUTE_HOMEPAGE}?orderid=:orderId`;
    return generatePath(routeWithOrderId, { orderId });
  }
  return generatePath(ROUTE_HOMEPAGE);
};

export const getLinkBillingInfo = (data) => {
  const path = generatePath(ROUTE_BILLING_INFO, { data });
  return join(env.UPDATE_BILLING_INFO, path);
};

export const getRouteBookCatalog = () => generatePath(ROUTE_BOOK_CATALOG);

export const getRouteMyOrders = () => generatePath(ROUTE_MY_ORDERS);

export const getRouteDigitalBooks = () => generatePath(ROUTE_DIGITAL_BOOKS);

export const getRouteMyOrdersCheckout = () => generatePath(ROUTE_CHECKOUT);

export const getRouteDashboard = () => generatePath(ROUTE_DASHBOARD);

export const getRouteBookPerformance = () => generatePath(ROUTE_BOOK_PERFORMANCE);

export const getRouteHelpCenter = () => generatePath(ROUTE_HELP_CENTER);

export const getRouteProfile = () => generatePath(ROUTE_PROFILE);

export const getRouteAutoLogin = (username, token, extraParams = '') =>
  generatePath(`${ROUTE_AUTO_LOGIN}/?username=${username}&token=${token}&${extraParams}`);

export const getRouteReleaseNotes = () => generatePath(ROUTE_RELEASE_NOTES);

export const getRouteShippingAddress = () => generatePath(ROUTE_EDIT_SHIPPING_ADDRESS);

export const getRouteChangePassword = () => generatePath(ROUTE_CHANGE_PASSWORD);
export const getRouteProfilePlans = () => generatePath(ROUTE_PROFILE_PLANS);

export const getRoutePageProfile = () => generatePath(ROUTE_PAGE_PROFILE);

export const getRouteLogin = () => generatePath(ROUTE_LOGIN);

export const getRouteLeads = () => generatePath(ROUTE_LEADS);

export const getRouteImportLeads = () => generatePath(ROUTE_IMPORT_LEADS);

export const getRouteBilling = () => generatePath(ROUTE_BILLING);

export const getRoutePlans = () => generatePath(ROUTE_PLANS);
export const getRoutePayments = () => generatePath(ROUTE_PAYMENTS);

export const getRouteEmailHistory = (leadId, origin) =>
  generatePath(ROUTE_EMAIL_HISTORY, {
    leadId,
    origin,
  });

export const getRouteEmailSenderEmailConfiguration = () =>
  generatePath(ROUTE_EMAIL_SENDER_EMAIL_CONFIGURATION);

export const getRouteEmailTemplates = () => generatePath(ROUTE_EMAIL_TEMPLATES);

export const getRouteEmailCampaignsAnalytics = () => generatePath(ROUTE_EMAIL_CAMPAIGNS_ANALYTICS);

export const getRouteCampaignHistory = (id) => generatePath(ROUTE_CAMPAIGNS_HISTORY, { id });

export const getRouteAllCampaignMetrics = () => generatePath(ROUTE_ALL_CAMPAIGN_METRICS);

export const getManageBookVersion = () => generatePath(ROUTE_MANAGE_BOOK_VERSION);

export const getOrderedBooksRoute = () => generatePath(ROUTE_MY_ORDERS);

export const getOrderedHistoryRoute = () => generatePath(ROUTE_MY_ORDER);

export const getMyCheckout = (status, orderId) => {
  if (orderId) {
    const routeWithOrderId = `${ROUTE_MY_CHECKOUT}/?orderId=:orderId`;
    return generatePath(routeWithOrderId, { status, orderId });
  }
  return generatePath(ROUTE_MY_CHECKOUT, { status });
};

export const getRouteCreateOnDemandEmail = () => generatePath(ROUTE_CREATE_ON_DEMAND_EMAIL);

export const getRouteOnDemandEmail = (id) => generatePath(ROUTE_ON_DEMAND_EMAIL, { id });

export const getRouteLaunchCampaign = () => generatePath(ROUTE_EMAIL_LAUNCH_CAMPAIGN);

export const getRouteEmailCampaign = (id) => generatePath(ROUTE_EMAIL_CAMPAIGN, { id });

export const getRouteCreateEmailCampaign = (content) =>
  generatePath(ROUTE_CREATE_EMAIL_CAMPAIGN, { content });

export const getUpgradePlan = () => generatePath(ROUTE_UPGRADE_PLAN);

export const getRouteCreateCustomerTemplate = (templateBaseId) =>
  generatePath(ROUTE_CREATE_CUSTOMER_TEMPLATE, { templateBaseId });

export const getRouteCreateCustomerTemplateFromScratch = () =>
  generatePath(ROUTE_CREATE_CUSTOMER_TEMPLATE);

export const getRouteEditCustomerTemplate = (id) =>
  generatePath(ROUTE_EDIT_CUSTOMER_TEMPLATE, { id });

export const goToHelpCenter = () => {
  const route = getRouteHelpCenter();

  return push(route);
};

export const getRouteReferralMarketingMagazineEditor = (
  month = env.RMM_CURRENT_MONTH || 'august',
  year = '2022',
) => generatePath(ROUTE_REFERRAL_MARKETING_MAGAZINE_EDITOR, { month, year });

export const getRouteReferralMarketingGeneratedMagazine = () =>
  generatePath(ROUTE_REFERRAL_MARKETING_GENERATED_MAGAZINE);

export const getRouteReferralMarketingPreviousMagazines = () =>
  generatePath(ROUTE_REFERRAL_MARKETING_PREVIOUS_MAGAZINES);

export const getRouteReferralMarketingMagazineRecipients = () =>
  generatePath(ROUTE_REFERRAL_MARKETING_MAGAZINE_RECIPIENTS);

export const getRoutePlansAndBillings = () => generatePath(ROUTE_PLANS_AND_BILLING);

export const getRoutePlanUpgrade = () => generatePath(ROUTE_PLAN_UPGRADE);

export const getRouteBuyCredits = () => generatePath(ROUTE_BUY_CREDITS);

export const getRouteWelcomeJourney = (task) =>
  generatePath(`${ROUTE_WELCOME_JOURNEY}?task=${task}`);

export const getRouteGuideCatalog = () => generatePath(ROUTE_GUIDE_CATALOG);

export const getRouteGuidePreview = (guideName) => generatePath(ROUTE_GUIDE_PREVIEW, { guideName });