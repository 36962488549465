const mockData = [
  {
    id: 1,
    name: 'seller-guide',
    title: 'A Guide to Selling Your Home + Infographic',
    description: 'This guide will help you sell your home quickly and for the best price.',
    guideColor: ['blue', 'gold', 'white', 'purple', 'red'],
    guideData: [
      {
        id: 1,
        color: 'red',
        coverUrl: 'https://afy-cms.s3.amazonaws.com/red_e4f67ca3e5.jpg',
        orderStatus: 'new',
        orderId: null,
      },
      {
        id: 2,
        color: 'gold',
        coverUrl: 'https://afy-cms.s3.amazonaws.com/gold_ccb16d06ea.jpg',
        orderStatus: 'ordered',
        orderId: '0001',
      },
      {
        id: 3,
        color: 'white',
        coverUrl: 'https://afy-cms.s3.amazonaws.com/white_d7b0b4c52d.jpg',
        orderStatus: 'finalize',
        orderId: '0002',
      },
      {
        id: 4,
        color: 'purple',
        coverUrl: 'https://afy-cms.s3.amazonaws.com/purple_26d3cd729c.jpg',
        orderStatus: 'new',
        orderId: null,
      },
      {
        id: 5,
        color: 'blue',
        coverUrl: 'https://afy-cms.s3.amazonaws.com/blue_ccd6ff0bf1.jpg',
        orderStatus: 'new',
        orderId: null,
      }
    ]
  }
];

export default mockData;

export const personalInformation = {
  profileImage: 'https://afy-users-files.s3.amazonaws.com/834B246F-5481-418A-8599-6955D667398C.jpeg',
  companyName: 'Exp Realty, LLC',
  address: '145, Santhaippet, salem - 637502',
  phoneNumber: '978.728.0487',
  companyLogo: 'https://afy-ui.s3.us-east-1.amazonaws.com/image-broker.png'
};

export const responseData =
  {
    id: 1,
    name: 'seller',
    title: 'A Guide to Selling Your Home',
    guideColor: ['red', 'gold', 'white', 'purple', 'blue']
  };